<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item class="ui-form__item" name="name" label="标签名称">
            <a-input v-model:value="formState.name" placeholder="请输入标签名称" />
          </a-form-item>

          <a-form-item class="ui-form__item" name="isDisabled" label="状态">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 200px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['cinema_basis_filmtags_add']" type="primary" @click="onAdd">新增</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">

            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['cinema_basis_filmtags_edit']" @click="toEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_filmtags_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_filmtags_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_filmtags_del']" @click="onDelete(record)">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

    <a-modal v-model:visible="showModal" destroyOnClose :title="modelRef.id ? '修改影院标签' : '添加影院标签'" width="500px" @ok="onSubmit">
      <a-spin :spinning="loading">
        <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
          <a-form-item label="标签名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
            <a-input :maxLength="6" v-model:value="modelRef.name" placeholder="请输入标签名称"/>
          </a-form-item>

          <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
            <a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="modelRef.sort" placeholder="请输入优先级"></a-input-number>
          </a-form-item>

          <a-form-item label="是否启用" name="isDisabled">
            <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>

  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import {getCinemaTagList, updateCinemaTag, saveCinemaTag, deleteCinemaTag} from "@/service/modules/cinema";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      showAll: false,
      modelRef: {},
      searchData: {},
      formState: {},
      columns: [{
        title: '标签名称',
        dataIndex: 'name',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getCinemaTagList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        });
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.list = ret.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    onAdd() {
      this.id = 0;
      this.showModal = true;
      this.modelRef = {};
      this.modelRef.isDisabled = true;
    },
    toEdit(item) {
      this.id = item.id;
      this.showModal = true;
      this.isEdit = true
      this.modelRef = JSON.parse(JSON.stringify(item));
      this.modelRef.isDisabled = this.modelRef.isDisabled ? false : true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    // 修改状态
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该影院标签吗？',
        onOk: async () => {
          let ret;
          this.loading = true;
          try {
            ret = await updateCinemaTag({
              name: item.name,
              sort: item.sort + '',
              id: item.id,
              isDisabled: (item.isDisabled ? 0 : 1) + ''
            });
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteCinemaTag({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onSubmit() {
      this.$refs.form.validateFields().then(async () => {
        if(!this.modelRef.name) {
          this.$message.error('请输入标签名称');
          return;
        }
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        this.loading = true;
        try {
          let ret;
          if(this.isEdit && this.id) {
            postData.id = this.id;
            ret = await updateCinemaTag(postData);
          } else {
            ret = await saveCinemaTag(postData);
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.getData();
            this.showModal = false;
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
